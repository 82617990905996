import { random } from 'lodash'
import config from 'config'
import { FileTypes } from 'types/s3File'

export const stringToHTML = function (str: string): HTMLElement {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  return doc.body;
};

// Source: https://stackoverflow.com/questions/10527983/best-way-to-detect-mac-os-x-or-windows-computers-with-javascript-or-jquery
export function isMacintosh() {
  return navigator.platform.indexOf('Mac') > -1;
}

export function isWindows() {
  return navigator.platform.indexOf('Win') > -1;
}

export function isWebsiteValid(website: string | null): boolean {
  try { 
    return Boolean(new URL(website as string))
  } catch (e){
    return false
  }
}

export function getSocialNetwork(url: string | null): string {
  if (!url) return ''
  if (url.includes('facebook.com')) return 'facebook'
  if (url.includes('instagram.com')) return 'instagram'
  if (url.includes('linkedin.com')) return 'linkedin'
  if (url.includes('twitter.com')) return 'twitter'
  if (url.includes('x.com')) return 'twitter'
  return ''
}

export const getItemType = (item: any) => {
  return item.type === 'post' ? 'story' : !!item.item_type ? 'item' : 'collection'
}

export const removeHTMLTags = (str: string) => {
  const helperEl = document.createElement('div')
  helperEl.innerHTML = str
  return helperEl.innerText
}

export const removeExtraNewlines = (text: string) => {
  return text.replace(/\n{3,}/g, '\n\n').replace(/\n*$/, '').replace(/^\n*/, '')
}

export const sanitizeInput = (str: string) => {
  return removeExtraNewlines(removeHTMLTags(str))
}

export const shuffleArray = (arr: any[]) => {
  if (!arr?.length) return []

  const newArr = arr.slice()
  for (let i = newArr.length - 1; i > 0; i--) {
      const rand = Math.floor(Math.random() * (i + 1));
      [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
  }
  return newArr
}

export const getCollectionFallbackImage = (data, preferedSize = 720) => {
  return data.image_subitem
    ? data.image_subitem.thumbnails?.[`${preferedSize}`] || config.urls.BASE_CONTENT_URL + data.image_subitem.s3_key
    : data.file?.type === FileTypes.Image
      ? data.file.thumbnails?.[`${preferedSize}`] || data.file.url
      : null
}

export const tryGetImage = (data, preferedSize = 720) => {
  if (!!data.poster) return `${config.urls.BASE_CONTENT_URL}${data.poster}.thumbnail${preferedSize}.webp`
  if (data.record_type === 'collection') {
    return getCollectionFallbackImage(data)
  } else if (data.file?.type === FileTypes.Image) {
    return data.thumbnails?.[`${preferedSize}`] || data.file.thumbnails?.[`${preferedSize}`] || data.file?.url
  } else if (data.file?.type === FileTypes.Audio) {
    return `/placeholders/audio-thumb-${random(1, 14)}.webp`
  } else if (data.record_type === 'story') {
    return data.thumbnails?.[`${preferedSize}`] || `/placeholders/pdf-thumb-${random(1, 12)}.jpg`
  } else if (data.featured_media) {
    const thumbnails = data._embedded['wp:featuredmedia']?.[0]?.media_details.sizes
    return thumbnails.medium_large?.source_url || thumbnails.full?.source_url
  }

  return data.file?.poster || `/placeholders/pdf-thumb-${random(1, 12)}.jpg`
}